<template>
  <div>
    <vue-event-calendar :events="demoEvents"
      @monthChanged="monthChange" @dayChanged="dayChange">
    </vue-event-calendar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      demoEvents: [{
        date: '2018/3/12', // 必填
        title: 'Foo' // 必填
      }, {
        date: '2018/3/15',
        title: 'Bar',
        desc: 'description',
        customClass: 'disabled highlight'
      }]
    }
  },
  methods: {
    monthChange (month) {
    },
    dayChange (day) {
    }
  }
}
</script>
